body {
  padding-top: 90px;
  font-family: "Work sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
}

.flips-header {
  margin-top: 40px;
  margin-bottom: 10px;
  font-weight: 600;
  color: #444;
}

.flips-bottom-border {
  border-bottom: 1px solid #e7e7e7;
}

.content-container {
  color: #444;
  margin-bottom: 30px;
}

.content-container-bottom {
  color: #444;
  margin-bottom: 30px;
}

.vcenter {
  display: inline-block;
  vertical-align: middle;
  float: none;
}

.flips-nav-dropdown ul.dropdown-menu {
  right: auto;
}

@media (max-width: 1020px) {
  .navbar-header {
    float: none;
  }
  .navbar-toggle {
    display: block;
  }
  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  }
  .navbar-collapse.collapse {
    display: none !important;
  }
  .navbar-nav {
    float: none !important;
    margin: 7.5px -15px;
  }
  .navbar-nav > li {
    float: none;
  }
  .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .navbar-text {
    float: none;
    margin: 15px 0;
  }
  /* since 3.1.0 */
  .navbar-collapse.collapse.in {
    display: block !important;
  }
  .collapsing {
    overflow: hidden !important;
  }
}

.label-corona {
  font-weight: normal;
  background-color: #475269;
  /* background-color: #a81d21; */
}

.table > tbody > tr:first-child > td {
  border-top: none;
}
