
.counter-value {
  font-size: 18px;
  /*font-weight: 600;*/
  color: #777;
}

.counter-label {
  font-size: 12px;
  color: #777;
}

.cat-header {

  /*height:70px;*/
  float: none;
  display: table-cell;
  height:80px;
  /* height:120px; */
  /* vertical-align: bottom; */

}

.cat-header-content {
  margin-bottom: 16px;
}


.cat-header h3 {
  margin-bottom: 0px;

}
