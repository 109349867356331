.rules td{
  font-size: 16px;
  color:#444;
  padding-bottom: 18px;
  /*line-height: 1.4;*/
}

.rules tr{
  padding-bottom: 18px;
  vertical-align: top;
}
