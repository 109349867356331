.footer {
  position: relative;
  bottom: 0;
  width: 100%;
}

.flips-footer {
  padding-bottom: 30px;
  color: #777;
  border-top: 1px solid #62bfe4;
  font-weight: 200;
  /* background-image: url("./home/banner_bg_2025.jpg"); */
  background: linear-gradient(
      to bottom,
      /* rgba(255, 255, 255, 0) 0%, */ rgba(250, 250, 250, 0.4) 20%,
      /* rgba(250, 250, 250, 0.8) 50%,
      rgba(250, 250, 250, 0.6) 80%, */
        rgba(255, 255, 255, 0.1) 100%
    ),
    url("./home/banner_bg_2025.jpg");
}

.flips-footer::before {
  content: "";
  background-color: #f5f5f5;
  background-image: url("./utils/balls.png");

  background-size: auto;
  opacity: 0.15;

  display: block;
  position: absolute;
  left: 0;
  top: 0;

  height: 100%;
  width: 100%;
  z-index: -2;
}

.footer-widget a {
  color: #777;
}

.flips-footer a {
  font-size: 18px;
  margin-right: 20px;
}

.flips-footer h3 {
  font-weight: 200;
}

.flips-footer-body {
  font-size: 18px;
}

.flips-footer .glyphicon {
  color: #ddd;
  font-size: 28px;
  margin-right: 6px;
  vertical-align: middle;
}
