.flips-banner {
  width: 100%;
  overflow: hidden;
  display: block;
  position: relative;
  color: black;
  /*background-color: #f5f5f5;*/
  /* border-bottom: 1px solid #e7e7e7; */
  border-bottom: 1px solid white;
  background: linear-gradient(
      to bottom,
      rgba(250, 250, 250, 0.3) 20%,
      rgba(255, 255, 255, 0.3) 80%
    ),
    url("banner_bg_2025.jpg");
}

.flips-banner-text {
  margin-top: 12px;
  font-size: 20px;
  color: #444;
}

.flips-banner-header {
  color: #c73332;
  font-weight: 600;
  font-size: 30px;
  margin-top: 0;
}

.flips-banner-header a,
.flips-banner-header a:focus,
.flips-banner-header a:hover {
  color: #c73332;
  text-decoration: underline;
}

/* .flips-banner-bg::before {
  content: "";
  background-color: #fefefe;
  display: block;
  position: absolute;
  left: 0;
  top: 0;

  height: 100%;
  width: 100%;
  z-index: -2;
} */

/* .flips-banner-bg::after {
  content: "";
  background: url("balls.png");
  background-size: auto;

  opacity: 0.25;
  display: block;
  position: absolute;
  left: 0;
  top: 0;

  height: 100%;
  width: 100%;
  z-index: -1;
} */

.flips-banner-bc {
  font-size: 16px;
  margin-bottom: 3px;
}
