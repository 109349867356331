.markdown {
  font-size: 18;
}

.markdown p {
  margin-top: 20px;
}

.markdown h3 {
  margin-top: 40px;
  margin-bottom: 10px;
  font-weight: 600;
  color: #444;
  font-size: 24px;
}

.markdown strong:first-child {
  margin-right: 10px;
}
