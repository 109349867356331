.apps {
  font-size: 18px;
  color: #444;
}

.apps h3 {
  font-weight: 600;
  margin-bottom: 20px;
  color: #a81d21;
}

.apps ul {
  padding-left: 16px;
  padding-right: 32px;
}

.badges img {
  margin-top: 10px;
  height: 60px;
}

.badges {
  margin-top: 20px;
}

.device-container {
  max-height: 580px;
  overflow: hidden;
  margin-top: -30px;
}

.device-container img {
  width: 100%;
}

.apps-desc {
  /*margin-top: 80px;*/
}

.apps-block {
  vertical-align: middle;
  /*border-bottom: 1px solid #e7e7e7;*/
}

.badge-link img {
  margin-bottom: 10px;
}
