
.offers-header {
  text-align:center;
  margin-top:40px;
  margin-bottom:4px;
  color:#c73332;
  font-weight: 700;
}

.offers-subheader {
  text-align:center;
  font-size: 18px;
  margin-bottom:18px;
  color:#444;

}

.offers-subheader a {
  /*font-weight: bold;*/
}


.offers-category-block h4{
  margin-top: 20px;
  margin-bottom: 16px;

  padding-bottom: 8px;
  border-bottom: 4px solid #62bfe4;
  font-size: 20px;
}

.offers-others img {
  border: 1px solid #e7e7e7;
}


.offers-category-block .thumbnail img {
  border: 1px solid #e7e7e7;
}

/* Extra Small */
@media(max-width:767px){}

/* Small */
@media(min-width:768px) and (max-width:991px){

  .offers-category-block_content_left {
    margin-right: 25px;
  }

  .offers-category-block_content_right {
    margin-left: 25px;
  }

}

/* Medium */
@media(min-width:992px) and (max-width:1199px){

  .offers-category-block_content_left {
    margin-right: 30px;
  }

  .offers-category-block_content_right {
    margin-left: 30px;
  }

}

/* Large */
@media(min-width:1200px){

  .offers-category-block_content_left {
    margin-right: 40px;
  }

  .offers-category-block_content_right {
    margin-left: 40px;
  }

}
