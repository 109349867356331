
.navbar {
  padding: 20px 0;
}

nav.navbar-default {
  background-color: #ffffff;
}

.flips-navbar-brand {
    float: left;
    height: 50px;
    padding: 0;
    font-size: 18px;
    line-height: 20px;
}


html, body {
  height: 100%;
}

#wrap {
  min-height: 100%;
  height: auto;
  /* Negative indent footer by its height */
  /*margin: 0 auto -60px;
  padding: 0 0 60px;*/
}
