/* main color */

.main-color,
.flips-slider-header,
.offers-header,
.news h3,
.newsbox-block h3,
.form-error,
.flips-banner-header,
.flips-banner-header a,
.flips-banner-header a:focus,
.flips-banner-header a:hover,
.offer-coupon-header .offer-coupon-header_amount {
  color: #a81d21;
  /* color: #c0186a; */
}

.coupon-title {
  background-color: #a81d21;
}

/* second color */
.flips-footer {
  border-top-color: #475269;
}

.offers-category-block h4 {
  border-bottom-color: #475269;
}

.news-box {
  background-color: rgba(217, 241, 251, 0.48);
  border-left-color: #475269;
}

/* others */

.flips-footer::before {
  /* background-color: #f5f5f5; */
  /* background-image: url("./utils/b2019.png"); */
  background-image: linear-gradient(#dce5e7, #f3f6f7);
  opacity: 0.7;
}

.newsbox-block::before {
  /* background: url("./utils/b2019.png"); */
  background-image: linear-gradient(#dce5e7, #f3f6f7);
  opacity: 0.3;
}

.gastro-filterbox-block::before {
  background-image: linear-gradient(#dce5e7, #f3f6f7);
  opacity: 0.3;
}

/* .flips-banner-bg::after {
  background: url("./home/banner_bg_2025.jpg");
  opacity: 0.07;
} */

.newsbox-block {
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
}

.flips-banner-bg::before {
  background-color: #fefefe;
}

.coupon {
  background-color: #e7e7e7;
}

.flips-pager-header {
  background-color: #f5f5f5;
}

.btn-info {
  background-color: #475269;
  border-color: #475269;
}

.btn-info:hover,
.btn-info:active,
.btn-info:focus,
.btn-info:active:hover {
  background-color: #333f59;
  border-color: #333f59;
}

.offers-category-block h4 a {
  color: #475269;
  font-weight: bold;
}

h3.flips-header {
  color: #475269;
}

.flips-footer h3 {
  color: #475269;
  /* font-weight: normal; */
}

.flips-footer .glyphicon,
.footer-widget a {
  color: #475269;
}
