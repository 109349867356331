.news {
  font-size: 16px;
  color:#444;
}

.news h3 {
  font-weight: 600;
  color:#c73332;
  text-align: center;
}

.news-box {

  background-color: rgba(217, 241, 251, 0.48);
  margin: 20px 0;
  margin-left: 15px;
  padding: 20px;
  border-left: 6px solid #62bfe4;
  font-size: 14px;
}

.news-box h3{
  color: #444;
  margin-top: 0;
  font-size: 18px;
}
