.flips-slider {
  width: 100%;
  overflow: hidden;
  display: block;
  position: relative;
  color: black;
  border-bottom: 1px solid #e7e7e7;
}

.flips-slider-text {
  margin-top: 12px;
  font-size: 18px;
  color: #444;
  max-width: 560px;
}

.flips-slider-header {
  text-shadow: 1px 1px 6px rgba(255, 255, 255, 0.6);
}

.flips-slider-text {
  text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.5);
}

.flips-slider-header {
  /*color:#777;*/
  color: #c73332;
  font-weight: 700;
  /*padding-top: 20px;*/
}

h1.flips-slider-header {
  margin-top: 10px;
}

.flips-slider::before {
  content: "";
  background-color: #fde1ba;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.1;

  height: 100%;
  width: 100%;
  z-index: -2;
}

.flips-slider::after {
  content: "";
  /*background:  url('https://braunschweig.homecompany.de/images/Media/braunschweig-homecompany-start-top.jpg');*/
  /* background: url("banner_bg1.jpg"); */
  /* background: url("bg_2019_2_lg.jpg"); */

  /* background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(250, 250, 250, 0.8) 30%,
      rgba(250, 250, 250, 0.8) 50%,
      rgba(250, 250, 250, 0.6) 80%,
      rgba(255, 255, 255, 0) 100%
    ),
    url("bg_2019_2_lg.jpg"); */

  background: url("banner_bg_2025.jpg");

  /* background: url("banner_bg_2021.jpg"); */
  background-size: cover;
  /* background-position-x: center; */

  opacity: 1;
  display: block;
  position: absolute;
  left: 0;
  top: 0;

  height: 100%;
  width: 100%;
  z-index: -100;
}

.flips-slider-content {
  /*max-width: 940px;*/
  margin: auto;
  margin-top: 20px;
  margin-bottom: 0px;
}

/* Extra Small */
@media (max-width: 767px) {
  #appImage {
    max-height: 366px;
  }

  /* .flips-slider::after {
    background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0) 40%,
        rgba(255, 255, 255, 0.7) 70%,
        rgba(255, 255, 255, 0.85) 100%
      ),
      url("bg_2019_2_lg.jpg");

    background-size: cover;
  } */
}

/* Small */
@media (min-width: 768px) and (max-width: 991px) {
  /* .flips-slider::after {
    background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0%,
        rgba(250, 250, 250, 0.6) 10%,
        rgba(250, 250, 250, 0.8) 50%,
        rgba(250, 250, 250, 0.6) 90%,
        rgba(255, 255, 255, 0) 100%
      ),
      url("bg_2019_2_lg.jpg");
    background-size: cover;
  } */
}

/* Medium */
@media (min-width: 992px) and (max-width: 1199px) {
  /* .flips-slider::after {
    background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0%,
        rgba(250, 250, 250, 0.6) 20%,
        rgba(250, 250, 250, 0.8) 50%,
        rgba(250, 250, 250, 0.6) 80%,
        rgba(255, 255, 255, 0) 100%
      ),
      url("bg_2019_2_lg.jpg");
    background-size: cover;
  } */
}

/* Large */
@media (min-width: 1200px) {
}

a.btn-info.btn-red {
  background-color: #a81d21;
  border-color: #a81d21;
}

a.btn-info.btn-red:focus {
  background-color: #8b1d1d;
  border-color: #8b1d1d;
}
