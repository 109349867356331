.newsbox {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.newsbox-block h3 {
  font-weight: bold;
  color: #c73332;
  font-size: 24px;
  margin-top: 0;
  margin-bottom: 0;
}

.newsbox-subheader {
  font-size: 20px;
  margin-bottom: 18px;
  /*font-weight: bold;*/
  color: #555;
}

.newsbox-block button {
  font-size: 18px;
}

.newsbox-form {
  margin-bottom: 18px;
}

.newsbox-form .input-group {
  margin-left: auto;
  margin-right: auto;
}

.newsbox-footer {
  color: #777;
  padding: 0 10px;
  max-width: 820px;
  font-size: 13px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}

.newsbox-block {
  position: relative;
  /*border-top: 5px solid #c73332;*/
  overflow: hidden;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  padding: 20px 50px;
  /*background-color: #e0f0f7;*/
}

/* Extra Small */
@media (max-width: 767px) {
  .newsbox-block {
    padding: 20px 14px;
  }
}

/* Small */
@media (min-width: 768px) and (max-width: 991px) {
  .newsbox-block {
    padding: 20px 30px;
  }
}

.newsbox-block::before {
  content: "";
  background: url("../utils/balls.png");
  background-size: auto;

  opacity: 0.2;
  display: block;
  position: absolute;
  left: 0;
  top: 0;

  height: 100%;
  width: 100%;
  z-index: -1;
}

.form-error {
  color: #c73332;
  margin-top: 5px;
}

.disabled.btn-info.disabled:hover,
.btn-info[disabled]:hover,
fieldset[disabled] .btn-info:hover,
.btn-info.disabled:focus,
.btn-info[disabled]:focus,
fieldset[disabled] .btn-info:focus,
.btn-info.disabled.focus,
.btn-info[disabled].focus,
fieldset[disabled] .btn-info.focus {
  background-color: #777;
  border-color: #777;
}
